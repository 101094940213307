import React from "react";
import { USER_COLORS } from "../constants";
import { awareness } from "../y";

// A random color for the current user
export const color =
  USER_COLORS[Math.floor(Math.random() * USER_COLORS.length)];

export function useUpdateCursor() {
  // A callback to update the local user's cursor
  const updateCursor = React.useCallback((point: number[], dimensions: number[]) => {
    awareness.setLocalState({
      id: awareness.clientID,
      point,
      dimensions,
      color
    });
  }, []);

  React.useEffect(() => {
    const handleUnload = () => {
      awareness.setLocalState(null);
    }

    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  return updateCursor;
}
