export const NUM_STARS = 500;
export const MIN_STAR_SIZE = 0.6;
export const RANDOM_THRESHOLD = 0.6;

export default class Star {
  x: number;
  y: number;
  size: number;
  alpha: number;
  opacity: number;
  hue: number;

  constructor(x: number, y: number, size: number) {
    this.x = x;
    this.y = y;
    this.size = size;
    this.alpha = size / (MIN_STAR_SIZE + RANDOM_THRESHOLD);
    this.opacity = Math.random()
    this.hue = Math.floor(Math.random() * 160);
  }

  draw(bgContext: any){
    bgContext.beginPath();
    let sizeModifier = (Math.random() - 0.8) * 0.5
    bgContext.arc(this.x, this.y, this.size + sizeModifier, 0, Math.PI*2, false);
    let color = "hsla("+ this.hue + ",100%,85%," + this.opacity * 0.6 + ")";
    bgContext.fillStyle = color;
    bgContext.fill();
  }

  update(bgContext: any){
    this.opacity = Math.random()
    this.hue = Math.floor(Math.random() * 160);
    this.draw(bgContext);
  }
}
