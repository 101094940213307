import * as Y from "yjs";
import { WebsocketProvider } from "y-websocket";

export const ydoc = new Y.Doc();
export const ymap = ydoc.getMap('shootingStars');

export const provider = new WebsocketProvider(
  `wss://tiny-campsite-2a529596978c.herokuapp.com/`,
  "",
  ydoc
);

export const awareness = provider.awareness;
