import * as React from "react";
import { usePerfectCursor } from "../hooks/usePerfectCursor";
import cursor from '../assets/cursor.png';
import Particle from "../effects/particle"

export const Cursor = React.memo(
  ({ point, dimensions, theme }: { point: number[], dimensions: number[], theme: boolean }) => {
    const rCursor = React.useRef<HTMLImageElement>(null);
    const [context, setContext] : any = React.useState(null);
    const particlesRef : any = React.useRef([]);
    const canvasRef : any = React.useRef(null);

    React.useLayoutEffect(() => {
      const dpr = window.devicePixelRatio || 1
      
      if (canvasRef.current) {
        const canvas : any = canvasRef.current;
        canvas.width = window.innerWidth*dpr;
        canvas.height = window.innerHeight*dpr;
        const ctx = canvas.getContext("2d");
        ctx.scale(dpr,dpr);
        setContext(ctx);
      }
      const interval = setInterval(() => {
        context.clearRect(0, 0, window.innerWidth, window.innerHeight);
        for(let i = 0; i < particlesRef.current.length; i++) {
          particlesRef.current[i].update();
          particlesRef.current[i].draw(context, theme);
  
          if(particlesRef.current[i].size <= 0.05){
              particlesRef.current.splice(i,1);
              --i;
          }
        }
      }, 2);
      return () => clearInterval(interval);
    }, [canvasRef, context, theme, dimensions]);

    const animateCursor = React.useCallback((point: number[]) => {
      const elm = rCursor.current;
      if (!elm) return;
      if (!dimensions) return;

      let otherWidth = dimensions[0] == 0 ? innerWidth : dimensions[0]
      let otherHeight = dimensions[1] == 0 ? innerHeight : dimensions[1]

      const scaledX = (innerWidth / otherWidth) * point[0]
      const scaledY = (innerHeight / otherHeight) * point[1]
      const boundedX = Math.min(Math.max(scaledX, 30), innerWidth - 30)
      const boundedY = Math.min(Math.max(scaledY, 30), innerHeight - 30)

      elm.style.setProperty(
        "transform",
        `translate(${boundedX}px, ${boundedY}px)`
      );
      const newParticles = Array.from({ length: 6 }, () => new Particle([boundedX, boundedY]));
      particlesRef.current = particlesRef.current.concat(newParticles);
    }, [dimensions]);

    const onPointMove = usePerfectCursor(animateCursor)

    // Update the point whenever the component updates
    onPointMove(point);

    return (
      <>
        <canvas className="cursorTrail" ref={canvasRef}></canvas>
        <img ref={rCursor} className="cursor" src={cursor} alt="Marshmallow on stick"/>
      </>
    )
  }
);