let num_unclustered_stars = (window.innerWidth / 1.5) * 10;
let num_clusters = (window.innerWidth * 1.4) / 10;

const STARS_PER_CLUSTER = 120;
const LAYERS_PER_CLUSTER = 10;
const MIN_CLUSTER_SIZE = 100;
const CLUSTER_ANGLE = 0.6;

const MIN_GRADIENT_HUE = 180;
const MAX_GRADIENT_HUE = 320;

class Cluster {
  x: number;
  y: number;
  size: number;
  hue: number;
  baseWhiteProportion: number;
  brightnessModifier: number;

  constructor(x: number, y: number, size: number, hue: number, baseWhiteProportion: number, brightnessModifier: number){
    this.x = x;
    this.y = y;
    this.size = size;
    this.hue = hue;
    this.baseWhiteProportion = baseWhiteProportion;
    this.brightnessModifier = brightnessModifier;
  }

  draw (bgContext: any, clusterContext: any) {
    let starsPerLayer = Math.floor(STARS_PER_CLUSTER/LAYERS_PER_CLUSTER);

    for(let layer = 1; layer < LAYERS_PER_CLUSTER; layer++){
      let layerRadius = this.size * layer / LAYERS_PER_CLUSTER;

      for(let i = 1; i < starsPerLayer; i++) {
        let posX = this.x + 2 * layerRadius*(Math.random() - 0.5);
        let posY = this.y + 2 * Math.sqrt(Math.pow(layerRadius, 2) - Math.pow(this.x - posX, 2)) * (Math.random() - 0.5);

        let size = 0.4 + Math.random() * 0.4;
        let alpha = 0.3 + Math.random() * 0.15;

        if (i == starsPerLayer - 1) {
          // If last layer, make stars more dispersed
          size += 1.5
          alpha -= 0.4
        }

        let whitePercentage = this.baseWhiteProportion + 15 + 15 * this.brightnessModifier + Math.floor(Math.random() * 10);
        clusterContext.beginPath();
        clusterContext.arc(posX, posY, size, 0, Math.PI*2, false);
        clusterContext.fillStyle = "hsla(" + this.hue +  ",100%," + whitePercentage + "%," + alpha + ")";
        clusterContext.fill();
      }
    }

    let gradient = bgContext.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.size);
    gradient.addColorStop(0, "hsla(" + this.hue +  ",100%," + this.baseWhiteProportion + "%,0.002)"); 
    gradient.addColorStop(0.25, "hsla(" + this.hue +  ",100%," + (this.baseWhiteProportion + 30) + "%,"+ (0.01+0.01*this.brightnessModifier) + ")");
    gradient.addColorStop(0.4, "hsla(" + this.hue +  ",100%," + (this.baseWhiteProportion + 15) + "%,0.005)");
    gradient.addColorStop(1, "rgba(0,0,0,0)");

    clusterContext.beginPath();
    clusterContext.arc(this.x,this.y,this.size, 0, Math.PI*2, false);
    clusterContext.fillStyle = gradient;
    clusterContext.fill();
  }
}

function getBiasedRand (min: number, max: number, bias: number, influence: number) {
    let rand = Math.random() * (max - min) + min
    let weight = Math.random() * influence;
    return rand * (1 - weight) + bias * weight;
}

function getX () {
    return Math.floor(getBiasedRand(innerWidth / 5, (4 * innerWidth) / 5, (1.7 * innerWidth) / 3, 0.3));
}

function getY (x: number, mode: string) {
  let height = innerHeight * 0.7
  let offset = ((innerWidth/2) - x) * CLUSTER_ANGLE;
  if (mode == "star") {
      return Math.floor(Math.pow(Math.random(),1.2)*height*(Math.random()-.5) + height/2 + (Math.random()-.5)*100) + offset;
  }
  else {
      return Math.floor(Math.pow(Math.random(),1.5)*height*.6*(Math.random()-.5) + height/2 + (Math.random()-.5)*100) + offset;
  }
}

export default function DrawMilkyWayCanvas(bgContext: any, clusterContext: any) {
  bgContext.clearRect(0, 0, window.innerWidth, window.innerHeight);
  clusterContext.clearRect(0, 0, window.innerWidth, window.innerHeight);

  let height = innerHeight * .7
  num_unclustered_stars = (window.innerWidth / 1.5) * 10;
  num_clusters = (window.innerWidth * 1.4) / 10;

  // Draw unclustered stars
  for(let i = 0; i < num_unclustered_stars; i++) {
    clusterContext.beginPath();
    let x = getX();
    let y = Math.random() < MIN_CLUSTER_SIZE ? Math.floor(Math.random() * height) : getY(x, "star");
    let size = Math.random() * 0.25;
    clusterContext.arc(x, y, size, 0, Math.PI * 2, false);

    let alpha = 0.4 + Math.random() * 0.6;
    clusterContext.fillStyle = "hsla(0,100%,100%," + alpha + ")";
    clusterContext.fill();
  }

  // Draw clustered stars
  for(let i = 0; i < num_clusters; i++) {
    let x = getX();
    let y = getY(x, "cluster");

    let distToCenter = (1 - (Math.abs(x - innerWidth / 2) / (innerWidth / 2))) * (1 - (Math.abs(y - height / 2) / (height / 2)));
    let size = MIN_CLUSTER_SIZE + Math.random() * MIN_CLUSTER_SIZE;

    let hue = MIN_GRADIENT_HUE + Math.floor((Math.random() * 0.5 + distToCenter * 0.5) * (MAX_GRADIENT_HUE - MIN_GRADIENT_HUE));

    let baseWhiteProportion = 50 + Math.random() * 10;
    new Cluster(x, y, size, hue, baseWhiteProportion, distToCenter).draw(bgContext, clusterContext);
  }
}