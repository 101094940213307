export default class Particle {
  x : number;
  y : number;
  size : number;
  speedx : number;
  speedy : number;

  constructor(p: number[]) {
    const [x, y] = p;
    this.x = x;
    this.y = y;
    this.size = Math.random() * 2 + 0.8;
    this.speedx = Math.random() * 0.75 - 0.25;
    this.speedy = Math.random() * 0.75 - 0.25;
  }

  update() {  
    this.x += this.speedx;
    this.y += this.speedy;
    if(this.size > 0) {
        this.size -= 0.01;
    }
  }

  draw(ctx : any, theme : boolean) {
    ctx.fillStyle = theme ? '#FFB7AD' : '#A27FBD99';
    ctx.beginPath();
    ctx.moveTo(this.x, this.y);
    let width = this.size;
    let height = this.size;
    ctx.lineTo(this.x - width / 2, this.y + height / 2);
    ctx.lineTo(this.x, this.y + height);
    ctx.lineTo(this.x + width / 2, this.y + height / 2);
    ctx.closePath();
    ctx.fill();
  }
};