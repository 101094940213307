import * as React from "react";
import { UserPresence } from "../types";
import { awareness } from "../y";
import throttle from "lodash.throttle";
import { THROTTLE } from "../constants";

export function useLiveCursors() {
  const [users, setUsers] = React.useState<UserPresence[]>([]);

  // Update the users state when the provider's awareness updates
  React.useEffect(() => {
    const handleChange = throttle(() => {
      setUsers(
        (Array.from(awareness.getStates().values()) as UserPresence[]).filter(
          (user) => user.id !== awareness.clientID
        )
      );
    }, THROTTLE);

    awareness.on("change", handleChange);
    return () => {
      awareness.off("change", handleChange);
    };
  }, []);

  return users;
}
