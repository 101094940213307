import * as React from "react";
import { Cursor } from "./Cursor";
import { useLiveCursors } from "../hooks/useLiveCursors";
import { useUpdateCursor } from "../hooks/useUpdateCursor";
import smoke from '../assets/smoke.png'
import smokeTinted from '../assets/smokeTinted.png';
import Particle from "../effects/particle"
import sound from '../assets/campfire.mp3'
import Star, { NUM_STARS, MIN_STAR_SIZE, RANDOM_THRESHOLD } from '../effects/star'
import DrawMilkyWayCanvas from '../effects/cluster'
import ShootingStar, { SHOOTING_STAR_COLORS, MIN_SHOOTING_STAR_XSPEED, MIN_SHOOTING_STAR_YSPEED } from "../effects/shootingStar";
import { ymap } from "../y";

import chime1 from '../assets/chime1.mp3'
import chime2 from '../assets/chime2.mp3'
import chime3 from '../assets/chime3.mp3'
import chime4 from '../assets/chime4.mp3'
import chime5 from '../assets/chime5.mp3'

export function Campsite() {
  const [theme, setMode] = React.useState(true);
  const [isPlaying, setPlaying] = React.useState(false);
  const [isResized, setResized] = React.useState([window.innerWidth, window.innerHeight]);
  const [cursorTrailCtx, setCursorTrailCtx] : any = React.useState(null);
  const [backgroundCtx, setBackgroundCtx] : any = React.useState(null);
  const [clusterCtx, setClusterCtx] : any = React.useState(null);
  const particlesRef : any = React.useRef([]);
  const starsRef : any = React.useRef([]);
  const shootingStarsRef : any = React.useRef([]);
  const shootingStarSoundRef : any = React.useRef(null);
  const cursorTrailRef : any = React.useRef(null);
  const backgroundRef : any = React.useRef(null);
  const clusterRef : any = React.useRef(null);
  const audioRef : any = React.useRef(null);

  const shootingStarSounds = [chime1, chime2, chime3, chime4, chime5]

  React.useEffect(() => {
    const handleResize = () => {      
      setResized([window.innerWidth, window.innerHeight])
    };

    window.addEventListener('resize', handleResize);

    const newShootingStar = (event : any) => {
      if (event.keys.has('newShootingStar') && !theme) {
          const [x, y, speedX, speedY, color, sound, screenWidth] = ymap.get('newShootingStar') as [number, number, number, number, string, number, number];

          let otherWidth = screenWidth == 0 ? innerWidth : screenWidth
          const scaledX = (innerWidth / otherWidth) * x
          const boundedX = Math.min(Math.max(scaledX, 30), innerWidth - 30)
    
          shootingStarsRef.current = shootingStarsRef.current.concat(new ShootingStar(boundedX, y, speedX, speedY, color))
          if (isPlaying) {
            shootingStarSoundRef.current.src = shootingStarSounds[sound]
            shootingStarSoundRef.current.play()  
          }
        }
    }
    ymap.observe(newShootingStar);
    return () => {
      window.removeEventListener('resize', handleResize);
      ymap.unobserve(newShootingStar);
    };
  }, [isPlaying, theme]);

  // An array of "live" cursors from the provider's awareness
  const cursors = useLiveCursors();

  // A function to update the user's own presence
  const updateCursor = useUpdateCursor();

  // When the user moves their pointer, update their presence
  const handlePointMove = (e: React.PointerEvent, isResized: number[]) => {
    let point = [e.clientX, e.clientY]
    const newParticles = Array.from({ length: 6 }, () => new Particle(point));
    particlesRef.current = particlesRef.current.concat(newParticles);
    updateCursor(point, isResized);
  }

  function init() {
    for(let i = 0; i < NUM_STARS; i++){
      let size = (Math.random() * RANDOM_THRESHOLD) + MIN_STAR_SIZE;
      let x = Math.random() * ((innerWidth - size *2 ) - (size * 2)) + size * 2;
      let y = Math.random() * ((innerHeight - size *2 ) - (size * 2)) + size * 2;

      starsRef.current.push(new Star(x, y, size));
    }
    DrawMilkyWayCanvas(backgroundCtx, clusterCtx);
  }


  React.useEffect(() => {
    const sendShootingStar = (e: any) => {
      if (e.key == " " && !theme) {
        // Send shooting star :)
        let x = Math.floor(Math.random() * innerWidth);
        let y = Math.floor(Math.random() * 150);
        let speedX = Math.floor((Math.random() - 0.5) * MIN_SHOOTING_STAR_XSPEED);
        let speedY = Math.floor(Math.random() * MIN_SHOOTING_STAR_YSPEED);
        let color = SHOOTING_STAR_COLORS[Math.floor(Math.random() * SHOOTING_STAR_COLORS.length)];
        let sound = Math.floor(Math.random() * shootingStarSounds.length)
        // shootingStarsRef.current.push(new ShootingStar(x, y, speedX, speedY, color));
        ymap.set('newShootingStar', [x, y, speedX, speedY, color, sound, isResized[0]]);

        if (isPlaying) {
          shootingStarSoundRef.current.src = shootingStarSounds[sound]
          shootingStarSoundRef.current.play()  
        }
      }
    }

    ymap.observe(sendShootingStar);
    window.addEventListener('keydown', sendShootingStar);

    return () => {
      window.removeEventListener('keydown', sendShootingStar);
      ymap.unobserve(sendShootingStar);
    };
  }, [isPlaying, theme])
  
  React.useEffect(() => {
    if (backgroundCtx && clusterCtx) {
      DrawMilkyWayCanvas(backgroundCtx, clusterCtx);
    }
  }, [isResized])

  React.useEffect(() => {
    isPlaying ? audioRef.current.play() : audioRef.current.pause()
  }, [isPlaying])

  React.useLayoutEffect(() => {
    const dpr = window.devicePixelRatio || 1;

    if (cursorTrailRef.current) {
      const cursorTrail = cursorTrailRef.current
      cursorTrail.width = window.innerWidth*dpr;
      cursorTrail.height = window.innerHeight*dpr;
      const ctx = cursorTrail.getContext("2d");
      ctx.scale(dpr,dpr);
      setCursorTrailCtx(ctx);
    }
    const interval = setInterval(() => {
      cursorTrailCtx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      for(let i = 0; i < particlesRef.current.length; i++) {
        particlesRef.current[i].update();
        particlesRef.current[i].draw(cursorTrailCtx, theme);

        if(particlesRef.current[i].size <= 0.05){
            particlesRef.current.splice(i,1);
            --i;
        }
      }
    }, 1);
    return () => clearInterval(interval);
  }, [cursorTrailRef, cursorTrailCtx, isResized, theme]);


  React.useLayoutEffect(() => {
    const dpr = window.devicePixelRatio || 1;

    if (backgroundRef.current && clusterRef.current) {
      const background = backgroundRef.current
      background.width = window.innerWidth*dpr;
      background.height = window.innerHeight*dpr;
      let ctx = background.getContext("2d")
      ctx.scale(dpr,dpr);
      setBackgroundCtx(ctx);

      const cluster = clusterRef.current
      cluster.width = window.innerWidth*dpr;
      cluster.height = window.innerHeight*dpr;
      ctx = cluster.getContext("2d")
      ctx.scale(dpr,dpr);
      setClusterCtx(ctx);
    }

    if (!theme && backgroundCtx && clusterCtx) {
      starsRef.current = []
      init()
    }

    const animateStars = setInterval(() => {
      if (!theme) {
        backgroundCtx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        for(let i = 0; i < starsRef.current.length; i++){
          starsRef.current[i].update(backgroundCtx);
        }
      }
    }, 100);

    const animateShootingStars = setInterval(() => {
      if (!theme) {
        for (let i = 0; i < shootingStarsRef.current.length; i++) {
          if(shootingStarsRef.current[i].isGoingOut()) {
            shootingStarsRef.current.splice(i, 1);
            i--;
          }
          else {
            shootingStarsRef.current[i].update(backgroundCtx);
          }
        }
      }
    }, 20);

    return () => {
      clearInterval(animateStars);
      clearInterval(animateShootingStars)
    }
  }, [theme, backgroundRef, backgroundCtx, clusterRef, clusterCtx, isResized]);

  return (
    <div className="container" onPointerMove={(e) => handlePointMove(e, isResized)}>
      {/* <p>{isResized}</p> */}
      <audio ref={audioRef} src={sound}></audio>
      <audio ref={shootingStarSoundRef}></audio>
      <canvas className="cursorTrail" ref={cursorTrailRef}></canvas>
      {!theme &&
      <>
        <canvas ref={backgroundRef}></canvas>
        <canvas ref={clusterRef}></canvas>   
      </>  
      }
      <div className="themeToggle" onClick={() => setMode(theme => {
        const body = document.getElementsByTagName('body')[0];
        body.className = !theme ? "theme" : "darkMode";
        return !theme;
      })}>🌙</div>
      <div className="soundToggle" onClick={() => setPlaying(isPlaying => !isPlaying)}>{isPlaying ? "🔊" : "🔇"}</div>
      <div className={theme ? "campsite" : "hidden"}>
        <div className="smokeWrap">
          <img className="smoke" src={theme ? smoke : smokeTinted} alt="smoke"/>
        </div>
        <div className="smokeWrap">
          <img className="smoke2" src={theme ? smoke : smokeTinted} alt="smoke"/>
        </div>
        <div className="smokeWrap">
          <img className="smoke3" src={theme ? smoke : smokeTinted} alt="smoke"/> 
        </div>
      </div>
      <div className={theme ? "hidden" : "campsiteDark"}>
        <div className="smokeWrap">
          <img className="smoke" src={theme ? smoke : smokeTinted} alt="smoke"/>
        </div>
        <div className="smokeWrap">
          <img className="smoke2" src={theme ? smoke : smokeTinted} alt="smoke"/>
        </div>
        <div className="smokeWrap">
          <img className="smoke3" src={theme ? smoke : smokeTinted} alt="smoke"/> 
        </div>
      </div>
      {cursors
        .filter((cursor) => cursor.point !== undefined)
        .map((cursor) => (
          <Cursor theme={theme} key={cursor.id} dimensions={cursor.dimensions} point={cursor.point} />
        ))}
    </div>
  );
}
