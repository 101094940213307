export const MIN_SHOOTING_STAR_XSPEED = 30;
export const MIN_SHOOTING_STAR_YSPEED = 15;
const MIN_LENGTH = 8;
const MIN_LIFESPAN = 60;

export const SHOOTING_STAR_COLORS = [
    "#D1B678",
    "#5461AD",
    "#ED9BAF",
    "#4E97B7"
];

export default class ShootingStar{
    x: number;
    y: number;
    speedX: number;
    speedY: number;
    framesLeft: number;
    color: string;

    constructor(x: number, y: number, speedX: number, speedY: number, color: string){
        this.x = x;
        this.y = y;
        this.speedX = speedX;
        this.speedY = speedY;
        this.framesLeft = MIN_LIFESPAN;
        this.color = color;
    }

    isGoingOut () {
        return this.framesLeft <= 0;
    }

    getHalfLife () {
        let halfLife = MIN_LIFESPAN / 2.0;
        return Math.pow(1.0 - Math.abs(this.framesLeft - halfLife) / halfLife, 2);
    }

    draw (bgContext: CanvasRenderingContext2D) {
        let age = this.getHalfLife();
        let endX = this.x - this.speedX * MIN_LENGTH * age;
        let endY = this.y - this.speedY * MIN_LENGTH*age;

        let gradient = bgContext.createLinearGradient(this.x, this.y, endX, endY);
        gradient.addColorStop(0, "#fff");
        gradient.addColorStop(Math.min(age, 0.7), this.color);
        gradient.addColorStop(1, 'transparent');

        bgContext.strokeStyle = gradient;
        bgContext.beginPath();
        bgContext.moveTo(this.x,this.y);
        bgContext.lineTo(endX,endY);
        bgContext.stroke();
    }
    
    update(bgContext: CanvasRenderingContext2D){
        this.framesLeft--;
        this.x += this.speedX;
        this.y += this.speedY;
        this.draw(bgContext);
    }
}