import "./styles.css";
import { Campsite } from "./components/Campsite";
import { Analytics } from "@vercel/analytics/react"

export default function App() {
  return (
    <div className="container">
      <Campsite />
      <Analytics />
    </div>
  );
}
